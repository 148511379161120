import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { LcasApiClientService } from '../../../../../shared/api-client/services/lcas-api-client/lcas-api-client.service';
import { Device } from '../../../../../shared/api-client/services/lcas-api-client/models/device.model';
import { SetupFormModel } from '../../../../../shared/api-client/services/lcas-api-client/models/setup-form.model';

@Injectable()
export class DeviceCommissioningService {
  constructor(
    private lcasApiClientService: LcasApiClientService,
    private router: Router,
  ) {}

  navigateToDeviceCommissioningWizard(
    deviceId: string,
    route: ActivatedRoute,
  ): Observable<boolean> {
    return from(
      this.router.navigate([
        'sidemenu',
        route.parent?.snapshot.params.projectId,
        'commissioning-v2',
        'connect',
        deviceId,
        'device-installation',
      ]),
    );
  }

  saveSetupFormAndCommission(
    deviceId: string,
    setupFormModel: SetupFormModel,
    eTag: number,
    credentialsToken?: string,
  ): Observable<Device> {
    return this.lcasApiClientService
      .patchFormDevice(deviceId, { form: setupFormModel, eTag })
      .pipe(
        switchMap((updatedDevice) =>
          this.lcasApiClientService.commissionPeripheralDevice(
            updatedDevice.id,
            {
              eTag: updatedDevice.eTag,
              borderRouterId: setupFormModel?.assignDevice as  // TODO: borderRouterId should be renamed in the clean-up step
                | string
                | undefined,
              credentialsToken,
            },
          ),
        ),
        first(),
      );
  }
}
